<template>
	<!-- 云市场 -->
	<div class="admin-adhibition-list el-content">
		<div class="aal-refresh" @click="refreshCloud">
			<i class="ri-refresh-line ri-top"></i>
			更新缓存
		</div>
		<div class="al-title">已开通</div>
		<div class="adhibition-list">
			<div class="aal-item" v-for="(item, index) in buy" :key="index">
				<img class="aali-img" v-if="item.plugin_logo == '#'" src="../../../assets/img/cover.jpg" alt="" />
				<img class="aali-img" v-else :src="item.plugin_logo" alt="" />
				<div>
					<div class="aali-name">{{ item.plugin_name }}</div>
					<div class="aali-sign">{{ item.plugin_desc }}</div>
					<div class="aali-buy">已开通</div>
				</div>
			</div>
		</div>
		<div class="al-title">未开通</div>
		<div class="adhibition-list">
			<div class="aal-item" v-for="(item, index) in shop" :key="index">
				<img class="aali-img" v-if="item.plugin_logo == '#'" src="../../../assets/img/cover.jpg" alt="" />
				<img class="aali-img" v-else :src="item.plugin_logo" alt="" />
				<div>
					<div class="aali-name">{{ item.plugin_name }}</div>
					<div class="aali-sign">{{ item.plugin_desc }}</div>
					<div class="aali-price" v-if="item.amount_actual > 0">￥{{ item.amount_actual }}</div>
					<div class="aali-buy" v-if="item.amount_actual == 0" @click="openAdhibition(item.plugin_id)">开通</div>
					<div class="aali-buy" v-else @click="buyAdhibition(item.plugin_id)">立即购买</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			buy: [],
			shop: []
		})
		getAdhibiton()

		function getAdhibiton(){
			programModel.getAllPlugin(res=>{
				state.buy = res.buy
				state.shop = res.shop
			})
		}

		function buyAdhibition(plugin_id){
			var newWindow = window.open(); //解决部分浏览器【目前发现QQ浏览器】出现发送请求之后window.open()打开链接被拦截
			programModel.buyPluginFormCloud(plugin_id,res=>{
				if( res.type == 'create_order' ){
					let url = res.cloud_url + '?order_sn=' + res.order_sn
					newWindow.location.href = url
					tool.confirm("请先确认是否已经已支付成功？","我已支付","放弃支付").then(()=>{
						refreshCloud()
					}).catch(()=>{
						tool.message("已放弃支付","warning")
						refreshCloud()
					})
				}
				if (res.type == 'create_plugin') {
					tool.message('开通成功');
					refreshCloud()
				}
			})
		}

		function openAdhibition(plugin_id){
			programModel.buyPluginFormCloud(plugin_id,res=>{
				if(res.type =='create_plugin' ){
					tool.message("开通成功")
					refreshCloud()
				}
			})
		}

		function refreshCloud(){
			programModel.cloudRefreshCache(()=>{
				getAdhibiton()
				location.reload()
			})
		}

		return{
			...toRefs(state),
			getAdhibiton,
			buyAdhibition,
			openAdhibition,
			refreshCloud
		}
	}
}
</script>

<style lang="scss">
.admin-adhibition-list {
	.aal-refresh {
		position: absolute;
		margin-left: 90%;
		cursor: pointer;
		color: #999;
	}
	.adhibition-list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.aal-item {
			width: 240px;
			height: 90px;
			border: 1px solid #f4f4f4;
			margin: 0 20px 20px 0;
			display: flex;
			align-items: center;
			cursor: pointer;
			font-size: 13px;

			.aali-img {
				width: 80px;
				height: 80px;
				margin: 0 5px;
			}

			.aali-sign {
				color: #999;
			}
			.aali-price {
				font-size: 16px;
				color: #f72424;
				font-weight: bold;
			}
			.aali-buy {
				color: #f72424;
			}
		}
		.aal-item:hover {
			border: 1px solid #55aaff;
		}
	}

	.al-title {
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 10px;
	}
}
</style>
